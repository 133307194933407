import React, { useState } from "react"
import AccountLayout from "../../../components/accountLayout"
import HasMultipleBeneficial from "../../../components/hasMultipleBeneficial"

interface formInput {
  multipleBeneficial: string
}

const HasMultipleBeneficialOwners = () => {
  const [isDisabled, setIsDisabled] = useState(true)
  const [formInput, setFormInput] = useState<formInput>()

  return (
    <AccountLayout
      title="Add beneficial owners"
      to={
        formInput?.multipleBeneficial === "yes"
          ? "/account-details/private-company-host/beneficial-owner"
          : "/beneficial-owner-review"
      }
      isDisabled={isDisabled}
    >
      <form id="form-id">
        <HasMultipleBeneficial setFormInput={setFormInput} setIsDisabled={setIsDisabled} />
      </form>
    </AccountLayout>
  )
}

export default HasMultipleBeneficialOwners
